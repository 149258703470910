<template>
  <div class="centerView ReceiveOrderTable">
    <div class="flexR" style="margin-bottom: 16px">
      <div class="operation-button">
        <a-space :size="18">
          <!-- <a-popconfirm :title="t('orderManageDetail.ReceiveOrderTable.objectionSubmit')" :ok-text="t('confirm')" :cancel-text="t('cancel')">
          </a-popconfirm> -->
          <a-button
            v-if="(pageType == 'view' && [10, 12].includes(Number(otherData.status))) || (pageType == 'add' && [10, 12].includes(Number(otherData.status)))"
            type="primary"
            size="large"
            @click="receiveOrder"
            style="margin-right: 42px"
          >
            {{ t('orderManageDetail.ReceiveOrderTable.receiveOrder') }}
          </a-button>
          <!-- 打版采购和生产采购时，不展示提交异议 -->
          <a-button
            v-if="
              otherData.type !== 40 &&
              otherData.type !== 90 &&
              ((pageType == 'view' && [10, 12].includes(Number(otherData.status))) || (pageType == 'add' && [10, 12].includes(Number(otherData.status))))
            "
            type="primary"
            danger
            size="large"
            class="padding-horizontal-20"
            @click="raiseObjection"
          >
            {{ t('orderManageDetail.ReceiveOrderTable.raiseObjection') }}
          </a-button>
          <!-- TODO 先不上线，生产采购时展示公益单按钮 -->
          <!-- <a-button v-if="otherData.type == 40" type="primary" danger size="large" @click="handleViewPublicWelfareList">
            {{ t('orderManageDetail.ReceiveOrderTable.checkPublicWelfareList') }}
          </a-button> -->
          <div class="form-item-button gray padding-horizontal-20" @click="exportOrderFn">
            <LoadingOutlined v-if="exportOrderSubmitting" />
            {{ t('orderManageDetail.ReceiveOrderTable.download') }}
          </div>
        </a-space>
      </div>
    </div>
    <a-table
      v-if="!tableLoading"
      :dataSource="dataSource"
      :columns="columns"
      :loading="tableLoading"
      :pagination="false"
      row-key="id"
      size="small"
      class="ant-table-striped"
      :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      :scroll="{ x: 1000 }"
    >
      <template #productPicTemplate="{ text }">
        <ImgGallery v-if="text" :urls="[text]" :img-width="39" :img-height="52" :show-img-num="1" :show-more="false" />
        <span v-else>-</span>
      </template>
      <template #productName="{ text }">
        <span>{{ text || '-' }}</span>
      </template>
      <template #productParams="{ text }">
        <!-- <ImgGallery /> -->
        {{ (JSON.parse(text || '{}') || {}).color_name || '-' }} / {{ (JSON.parse(text || '{}') || {}).size_name || '-' }}
      </template>
      <template #initPurchasePrice="{ record, text }">
        <a-space direction="vertical" :size="6">
          <div class="hover-input view">
            <div class="hover-input-left">
              <span>{{ t('orderManageDetail.ReceiveOrderTable.table.unitPrice') }}：</span>
              {{ text || 0 }}
            </div>
          </div>
          <!-- 打版采购和生产采购不展示 -->
          <div
            class="hover-input edit"
            v-if="otherData.type !== 90 && otherData.type !== 40"
            :class="{
              'no-empty': record.dissentPrice,
              view: record.dissentPrice && pageType == 'view' && ![10, 12].includes(Number(otherData.status)),
              different: record.initPurchasePrice != record.dissentPrice && pageType == 'view' && ![10, 12].includes(Number(otherData.status))
            }"
          >
            <div class="hover-input-left">
              <span>{{ t('orderManageDetail.ReceiveOrderTable.table.bargain') }}：</span>
              <a-input v-if="[10, 12].includes(Number(otherData.status))" v-model:value="record.dissentPrice" @change="e => dissentPriceInput(record)" />
              <span v-else>{{ record.dissentPrice || 0 }}</span>
            </div>
            <a-checkbox
              v-show="record.dissentPriceCheckbox !== undefined"
              v-model:checked="record.dissentPriceCheckbox"
              v-if="[10, 12].includes(Number(otherData.status))"
              @change="e => e.target.checked && dissentPriceCheckboxChange(record)"
              class="hover-input-right"
            >
              {{ t('orderManageDetail.ReceiveOrderTable.table.dissentPriceCheckboxText') }}
            </a-checkbox>
          </div>
        </a-space>
      </template>
      <template #quantityIncomGoods="{ record }">
        <span :style="{ color: `${record.receiptQuantity !== null && record.initPurchasePrice !== record.initPurchasePrice ? '#f53838' : ''}` }">
          {{ record.receiptQuantity || '--' }}
        </span>
      </template>
      <template #initQuantity="{ record, text }">
        <a-space direction="vertical" :size="6">
          <div class="hover-input view">
            <div class="hover-input-left">
              <span>{{ t('orderManageDetail.ReceiveOrderTable.table.demand') }}：</span>
              {{ text || 0 }}
            </div>
          </div>
          <div
            class="hover-input edit"
            :class="{
              'no-empty': record.dissentQuantity,
              view: record.dissentQuantity && pageType == 'view' && ![10, 12].includes(Number(otherData.status)),
              different: record.dissentQuantity != record.initQuantity && pageType == 'view' && ![10, 12].includes(Number(otherData.status))
            }"
          >
            <div class="hover-input-left">
              <span>{{ t('orderManageDetail.ReceiveOrderTable.table.canSent') }}：</span>
              <a-input v-if="[10, 12].includes(Number(otherData.status))" v-model:value="record.dissentQuantity" @change="e => dissentQuantityInput(record)" />
              <!-- <input v-if="pageType == 'add' && otherData.status == 10" type="text" v-model="record.dissentQuantity" @input="e => dissentQuantityInput(record)" /> -->
              <span v-else>{{ record.dissentQuantity || 0 }}</span>
            </div>
            <a-checkbox
              v-show="record.dissentQuantityCheckbox !== undefined"
              v-model:checked="record.dissentQuantityCheckbox"
              v-if="[10, 12].includes(Number(otherData.status))"
              @change="e => e.target.checked && dissentQuantityCheckboxChange(record)"
              class="hover-input-right"
            >
              {{ t('orderManageDetail.ReceiveOrderTable.table.dissentQuantityCheckboxText') }}
            </a-checkbox>
          </div>
        </a-space>
      </template>
    </a-table>

    <TableSkeleton v-if="tableLoading" />

    <!-- <pagination mt lable="" @change="onPageChange" :data="tableResult" @sizeChange="onSizeChange"></pagination> -->

    <CustomModal
      modalWidth="380px"
      v-model:modalVisible="receiveOrderModalVisible"
      :modalTitle="t('orderManageDetail.ReceiveOrderTable.confirmReceiveOrder')"
      :submitting="receiveOrderSubmitting"
      @submit="receiveOrderModelSubmit"
    >
      <div>
        <!-- TODO 先不上线，确认接单弹窗提示标题 -->
        <!-- <p class="ReceiveOrderTable-confirm-title">
          {{ t('orderManageDetail.ReceiveOrderTable.pleaseConfirmCarefully') }}
          <span class="ReceiveOrderTable-confirm-color-text">{{ t('orderManageDetail.ReceiveOrderTable.informationType') }}</span>
          {{ t('orderManageDetail.ReceiveOrderTable.importantInformation') }}
        </p> -->
        <a-form ref="receiveOrderModalFilterFormRef" :model="receiveOrderModalFilterForm" :rules="rules" style="width: 100%">
          <a-form-item name="dissentReceiptAt" :label="t('orderManageDetail.ReceiveOrderTable.dissentReceiptAt') + '：'">
            <a-date-picker
              v-model:value="receiveOrderModalFilterForm.dissentReceiptAt"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              :disabledDate="disabledDate"
              :placeholder="t('orderManageDetail.ReceiveOrderTable.dissentReceiptAt')"
              style="width: 100%"
            >
              <template #suffixIcon>
                <img style="width: 15px; height: 15px; display: flex" src="@/assets/images/table/timePickIcon.png" alt="" />
              </template>
            </a-date-picker>
          </a-form-item>
        </a-form>
      </div>
    </CustomModal>

    <CustomModal
      modalWidth="380px"
      v-model:modalVisible="raiseObjectionModalVisible"
      :modalBodyTitle="t('orderManageDetail.ReceiveOrderTable.objectionSubmit')"
      :modalBodyTips="t('orderManageDetail.ReceiveOrderTable.objectionSubmitTips')"
      :modalClosable="false"
      :modalCancelVisible="true"
      :modalOkText="t('submit')"
      modalFooterJustify="center"
      :submitting="raiseObjectionSubmitting"
      @submit="raiseObjectionModelSubmit"
    />
    <!-- 查看公益单抽屉 -->
    <a-drawer :title="t('orderManageDetail.ReceiveOrderTable.checkPublicWelfareList')" placement="right" width="80% !important" v-model:visible="publicWelfareListVisible">
      <p class="ReceiveOrderTable-download-text" @click="downloadPdf(otherData?.processSheetPdfUrlTemplate)">
        <DownloadOutlined />
        {{ t('orderManageDetail.ReceiveOrderTable.downloadIframe') }}
      </p>
      <iframe width="100%" frameborder="0" class="ReceiveOrderTable-iframe" :src="otherData?.processSheetPdfUrlTemplate"></iframe>
    </a-drawer>
  </div>
</template>

<script>
  /* eslint-disable */
  import { ref, reactive, watch, nextTick } from 'vue'
  import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'
  import { message } from 'ant-design-vue'
  import { LoadingOutlined, DownloadOutlined } from '@ant-design/icons-vue'
  import { transformColumnsI18n, filterInitialColumnsFn } from '@/language'
  import pagination from '@/components/pagination'
  import CustomModal from '@/views/supplier/components/CustomModal'
  import TableSkeleton from '@/views/supplier/components/TableSkeleton'
  import ImgGallery from '@/views/supplier/components/ImgGallery'
  import { useDownloadLocal } from '@/utils/use.js'
  import { acceptOrder, dissentOrder, exportOrder } from '@/api/supplier/orderManagement/orderManage'

  export default {
    name: 'ReceiveOrderTable',
    components: {
      pagination,
      CustomModal,
      TableSkeleton,
      ImgGallery,
      LoadingOutlined,
      DownloadOutlined
    },
    props: {
      status: {
        type: Number
      },
      tableLoading: {
        type: Boolean,
        default: false
      },
      data: {
        type: Array,
        default() {
          return []
        }
      },
      otherData: {
        type: Object,
        default() {
          return {}
        }
      },
      pageType: {
        type: String,
        default() {
          return ''
        }
      },
      tableType: {
        type: String,
        default() {
          return ''
        }
      },
      id: {
        type: Number
      }
    },
    emits: ['onPageChange', 'onSizeChange', 'exportOrderList', 'closeDrawerUpdateList'],
    setup(props, { emit }) {
      const route = useRoute()
      const router = useRouter()
      const pageType = props.pageType
      const tableType = props.tableType
      const id = props.id
      const { t, locale } = useI18n()

      const isEdit = ref(false)

      /*  */
      const initialColumns = [
        {
          title: 'orderManageDetail.ReceiveOrderTable.table.productPicTemplate',
          dataIndex: 'productPicTemplate',
          key: 'productPicTemplate',
          width: 70,
          slots: { customRender: 'productPicTemplate' }
        },
        {
          title: 'orderManageDetail.ReceiveOrderTable.table.manufacturingNo',
          dataIndex: 'manufacturingNo',
          key: 'manufacturingNo',
          width: 150
        },
        {
          title: 'orderManageDetail.ReceiveOrderTable.table.productParams',
          dataIndex: 'productParams',
          key: 'productParams',
          width: 180,
          slots: { customRender: 'productParams' }
        },
        {
          title: 'orderManageDetail.ReceiveOrderTable.table.initQuantity',
          dataIndex: 'initQuantity',
          key: 'initQuantity',
          width: 280,
          slots: { customRender: 'initQuantity' }
        },
        //判断待发货、完成订单增加到货数量字段
        {
          title: 'orderManageDetail.DeliveryTable.table.quantityIncomGoods',
          dataIndex: 'quantityIncomGoods',
          key: 'quantityIncomGoods',
          width: 100,
          slots: { customRender: 'quantityIncomGoods' }
        },
        {
          title: 'orderManageDetail.ReceiveOrderTable.table.sku',
          dataIndex: 'sku',
          key: 'sku',
          width: 150
        },
        {
          title: 'orderManageDetail.ReceiveOrderTable.table.initPurchasePrice',
          dataIndex: 'initPurchasePrice',
          key: 'initPurchasePrice',
          width: 280,
          slots: { customRender: 'initPurchasePrice' }
        },

        {
          title: 'orderManageDetail.ReceiveOrderTable.table.productName',
          dataIndex: 'productName',
          key: 'productName',
          width: 200,
          slots: { customRender: 'productName' }
        }
      ]
      // 查看公益单抽屉显示状态
      const publicWelfareListVisible = ref(false)
      // console.log('first', transformColumnsI18n(initialColumns))
      const columns = ref(transformColumnsI18n(initialColumns))
      watch(locale, () => {
        columns.value = transformColumnsI18n(initialColumns)
      })
      // const dataSource = ref(null);
      const dataSource = ref([])
      // const tableResult = ref(null);
      watch(
        () => props.data,
        newValue => {
          dataSource.value = newValue.map((item, index) => {
            let prev = newValue[index - 1]
            let next = newValue[index + 1]
            if (index == 0) {
              if (next && next.spu == item.spu) {
                item.dissentPriceCheckbox = false
                item.dissentQuantityCheckbox = false
              }
              return item
            }
            if (prev && prev.spu != item.spu && next && next.spu == item.spu) {
              item.dissentPriceCheckbox = false
              item.dissentQuantityCheckbox = false
            }
            return item
          })
        }
      )
      // function onPageChange(e) {
      //   filterForm.value.page = e;
      //   getTableData();
      //   emit('onPageChange')
      // }
      // function onSizeChange({ pageSize }) {
      //   filterForm.value.size = pageSize;
      //   filterForm.value.page = 1;
      //   getTableData();
      //   emit('onSizeChange')
      // }

      function setDataSource(spu, key, value) {
        dataSource.value = dataSource.value.map(item => {
          if (item.spu == spu) {
            item[key] = value
          }
          return item
        })
      }
      function dissentPriceInput({ spu, dissentPrice, dissentPriceCheckbox }) {
        if (dissentPriceCheckbox) {
          setDataSource(spu, 'dissentPrice', dissentPrice)
        }
      }

      function dissentQuantityInput({ spu, dissentQuantity, dissentQuantityCheckbox }) {
        if (dissentQuantityCheckbox) {
          setDataSource(spu, 'dissentQuantity', dissentQuantity)
        }
      }
      function dissentPriceCheckboxChange({ spu, dissentPrice }) {
        setDataSource(spu, 'dissentPrice', dissentPrice)
      }

      function dissentQuantityCheckboxChange({ spu, dissentQuantity }) {
        setDataSource(spu, 'dissentQuantity', dissentQuantity)
      }

      /* 接单 */
      const receiveOrderModalVisible = ref(false)
      const receiveOrderSubmitting = ref(false)
      const receiveOrderModalFilterFormRef = ref()
      const receiveOrderModalFilterForm = reactive({
        dissentReceiptAt: ''
      })
      function disabledDate(current) {
        return current && current < moment().startOf('day')
      }
      const rules = {
        dissentReceiptAt: [
          {
            required: true,
            validator: (rule, value) => {
              if (value === '') return Promise.reject(t('orderManageDetail.ReceiveOrderTable.dissentReceiptAtValidatorText'))
              return Promise.resolve()
            },
            trigger: 'change'
          }
        ]
      }
      function receiveOrder() {
        receiveOrderModalFilterForm.dissentReceiptAt = props.otherData.initReceiptAt
        receiveOrderSubmitting.value = false
        receiveOrderModalVisible.value = true
        nextTick(() => {
          receiveOrderModalFilterFormRef.value.resetFields()
        })
      }
      function receiveOrderModelSubmit() {
        receiveOrderModalFilterFormRef.value
          .validate()
          .then(res => {
            receiveOrderSubmitting.value = true
            acceptOrder({ id: Number(id), dissentReceiptAt: receiveOrderModalFilterForm.dissentReceiptAt })
              .then(res => {
                receiveOrderSubmitting.value = false
                if (res.code === 200) {
                  message.success(t('opSuccess'))
                  receiveOrderModalVisible.value = false
                  isEdit.value = true
                  // router.back()
                  emit('closeDrawerUpdateList')
                } else {
                  // message.error(res.msg)
                }
              })
              .catch(() => {
                receiveOrderSubmitting.value = false
              })
          })
          .catch(err => {})
      }

      /* 提交异议 */
      const raiseObjectionModalVisible = ref(false)
      const raiseObjectionSubmitting = ref(false)
      function raiseObjection() {
        raiseObjectionSubmitting.value = false
        raiseObjectionModalVisible.value = true
      }
      function raiseObjectionModelSubmit() {
        raiseObjectionSubmitting.value = true
        let purchaseOrderDetailDissentVoList = dataSource.value.map(({ id, dissentQuantity, dissentPrice }) => ({ id, dissentQuantity, dissentPrice }))
        let data = {
          purchaseOrderId: +id,
          expressFees: +props.otherData.expressFees,
          purchaseOrderDetailDissentVoList
        }
        dissentOrder(data)
          .then(res => {
            raiseObjectionSubmitting.value = false
            if (res.code === 200) {
              message.success(t('opSuccess'))
              raiseObjectionModalVisible.value = false
              isEdit.value = true
              router.back()
            } else {
              // message.error(res.msg)
            }
          })
          .catch(() => {
            raiseObjectionSubmitting.value = false
          })
      }

      /* 导出 */
      const exportOrderSubmitting = ref(false)
      function exportOrderFn() {
        if (exportOrderSubmitting.value) return
        exportOrderSubmitting.value = true
        emit('exportOrderList')
        // exportOrder({ id })
        //   .then((res) => {
        //     exportOrderSubmitting.value = false
        //     if (res.code === 200) {
        //       message.success(t('opSuccess'))
        //     } else {
        //       // message.error(res.msg)
        //     }
        //   })
        //   .catch(() => {
        //     exportOrderSubmitting.value = false
        //   })
      }
      const loadingTypeFun = () => {
        exportOrderSubmitting.value = false
      }

      onBeforeRouteLeave((to, from, next) => {
        to.meta.isEdit = isEdit.value
        to.meta.isBack = true
        next()
      })
      /**
       * 点击查看公益单
       */
      const handleViewPublicWelfareList = () => {
        publicWelfareListVisible.value = true
      }
      /**
       * 下载pdf
       * @param url pdf地址
       */
      const downloadPdf = url => {
        useDownloadLocal(url, `busniess_${new Date().getTime()}.pdf`)
      }
      return {
        t,
        pageType,
        tableType,
        columns,
        dataSource,
        // tableResult,
        // onPageChange,
        // onSizeChange,
        receiveOrder,
        receiveOrderModalVisible,
        receiveOrderModalFilterFormRef,
        receiveOrderModalFilterForm,
        disabledDate,
        rules,
        receiveOrderSubmitting,
        receiveOrderModelSubmit,
        /*  */
        raiseObjection,
        raiseObjectionModalVisible,
        raiseObjectionSubmitting,
        raiseObjectionModelSubmit,
        /*  */
        exportOrderFn,
        exportOrderSubmitting,
        dissentPriceInput,
        dissentQuantityInput,
        dissentPriceCheckboxChange,
        dissentQuantityCheckboxChange,
        loadingTypeFun,
        handleViewPublicWelfareList,
        publicWelfareListVisible,
        downloadPdf
      }
    }
  }
</script>

<style lang="less">
  .ReceiveOrderTable {
    .ant-space-item {
      flex-shrink: 0;
      width: auto !important;
    }
    .hover-input {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #989495;
      font-size: 14px;

      .hover-input-left {
        width: 150px;
        min-width: 150px;
        height: 30px;
        padding: 5px 10px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid #f6f6f6;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          flex-shrink: 0;
        }

        input[type='text'] {
          flex: 1;
          width: 100%;
          height: 100%;
          text-align: left;
          background-color: transparent;
          padding: 0;
          border: none !important;
          border-radius: 0 !important;

          &:focus {
            box-shadow: none !important;
          }
        }
      }
      .hover-input-right {
        color: #989495;
        margin-left: 10px;
        .ant-checkbox-inner {
          width: 20px;
          height: 20px;
          border: 1px solid #f6f6f6;
          border-radius: 6px;
        }
        .ant-checkbox-checked::after {
          border-radius: 6px;
        }
      }

      &.view {
        .hover-input-left {
          border-color: #d9d9d9;
          background-color: #f6f6f6;
        }
      }

      &.edit {
        color: #eaeaea;
        .hover-input-left {
          transition: all 0.3s;
        }
        .hover-input-right {
          color: #eaeaea;
          transition: all 0.3s;
        }
        &:hover {
          .hover-input-left {
            color: #131011;
            border-color: #ff6700;
          }
          .hover-input-right {
            color: #131011;
            .ant-checkbox-inner {
              border-color: #ff6700;
            }
          }
        }

        &.no-empty {
          .hover-input-left {
            color: #131011;
            border-color: #ff6700;
          }
          .hover-input-right {
            color: #131011;
            .ant-checkbox-inner {
              border-color: #ff6700;
            }
          }

          &.view {
            .hover-input-left {
              color: #989495;
              border: none;
              background-color: transparent;
            }
            &.different {
              .hover-input-left {
                color: #ff6700;
                border: none;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  .ReceiveOrderTable-confirm-title {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 153.846% */
    .ReceiveOrderTable-confirm-color-text {
      color: #ff6700;
      font-size: 13px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
    }
  }
  .ReceiveOrderTable-iframe {
    height: 800px;
  }
  .ReceiveOrderTable-download-text {
    color: #ff6700;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    line-height: 145%; /* 23.2px */
    letter-spacing: 0.07px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
</style>
