import { message } from 'ant-design-vue'
export const formatImg = (url, width, height) => {
  if (!width || !height) {
    return url.split('?')[0]
  }
  let _width = url.replace('{width}', width)
  let _height = _width.replace('{height}', height)
  return _height
}

export const useDownloadLocal = (url, fileName) => {
  const download = formatImg(url)
  fetch(download)
    .then(res => res.blob())
    .then(blob => {
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(link.href)
      document.body.removeChild(link)
      link = null
    })
    .catch(err => {
      console.log(err)
      message.error('Download failed, please try again later.')
    })
}
